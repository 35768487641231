<template>
  <div>
    <a-modal v-model:visible="dialogVisible"
             :maskClosable="false"
             title="二级/三级考点"
             :footer="null"
             width="1250rem">

      <div class="center_table">
        <div class="table_head">
          <div>
            考点
          </div>
          <div>星级</div>
          <div>得分率</div>
          <div>
            操作
          </div>
        </div>
      </div>
      <div class="table_line"
           v-for="(item,index) in list"
           :key="index">
        <div class="two">
          <div>
            {{item.knowledge_desc}}({{ item.level }}级考点)
          </div>
          <div>
            <img src="@/static/zhishidian/xx.jpg"
                 style="width:20rem;height:20rem"
                 v-for="(item,idx) in item.knowledge_point_star"
                 :key="idx" />
          </div>
          <div>
           {{item.rate}}
          </div>
          <div>
            <a-button type="primary"
                      @click="showOther(item)"
                      v-show="item.videos&&item.videos.length>0&&show_video">
              考点讲解
            </a-button>
            <a-button type="primary"
                      @click="viewLecture(item)"
                      v-show="item.lecture_data&&item.lecture_data.length>0">
              讲义数据
            </a-button>
            <a-button type="primary"
                      @click="toPaper(item)">
              进入训练
            </a-button>
          </div>
          <span class="icon"
                :style="{'background':item.active?'#2196F3':'#D8D8D8'}"
                v-if="item.child">
            <span>
              <plus-outlined @click="change(index,true)"
                             v-if="item.active == false" />
              <minus-outlined @click="change(index,false)"
                              v-else />
            </span>
          </span>
        </div>
        <div v-if="item.active">
          <div class="three"
               :style="{'border-bottom':idx == item.child.length -1 ?'':'1px solid #eaeaea'}"
               v-for="(itm,idx) in item.child"
               :key="itm.knowledge_id">
            <div>
              {{itm.knowledge_desc}}({{ itm.level }}级考点)
            </div>
            <div>
            <img src="@/static/zhishidian/xx.jpg"
                 style="width:20rem;height:20rem"
                 v-for="(itmm,idx) in itm.knowledge_point_star"
                 :key="idx" />
          </div>
          <div>
           {{itm.rate}}
          </div>
            <div>
              <a-button type="primary"
                        @click="showOther(itm)"
                        v-show="itm.videos&&itm.videos.length>0&&show_video">
                考点讲解
              </a-button>
              <a-button type="primary"
                        @click="viewLecture(itm)"
                        v-show="itm.lecture_data&&itm.lecture_data.length>0">
                讲义数据
              </a-button>
              <a-button type="primary"
                        @click="toPaper(item)">
                进入训练
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <OtherForm ref="otherFormRef" />

    <LectureForm ref="LectureFormRef" />
  </div>
</template>


<script setup>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import OtherForm from './other.vue'
import LectureForm from './lecture.vue'
import { computed, onMounted } from "@vue/runtime-core";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue'
const router = useRouter()
let dialogVisible = ref(false)
let list = ref([])
let subjectId = ref('')
const otherFormRef = ref(null)

const LectureFormRef = ref(null)
let show_video = ref(null)
onMounted(() => {
  show_video.value = JSON.parse(window.localStorage.getItem('userInfo')).show_video
 
})
const toPaper = (item) => {
  dialogVisible.value = false
  let form = {
    subject_id: subjectId.value,
    knowledge_id: item.knowledge_id
  }
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  router.push('/doPaper?isKnowledge=1&form=' + JSON.stringify(form))
}
const viewLecture = (item) => {
  LectureFormRef.value.list = item.lecture_data
  LectureFormRef.value.dialogVisible = true
}
const showOther = (item) => {

  otherFormRef.value.list = item.videos
  otherFormRef.value.dialogVisible = true
}

const change = (index, bool) => {
  list.value[index].active = bool
}

defineExpose({
  dialogVisible, list, subjectId
})
// export default {
//   data () {
//     return {
//       dialogVisible: false,
//       list: [],
//       subjectId: ''
//     }
//   },
//   methods: {
//     handleClose () {
//       this.dialogVisible = false
//     },
//     toPaper (item) {
//       this.handleClose()
//       let form = {
//         subject_id: this.subjectId,
//         knowledge_id: item.knowledge_id
//       }
//       window.sessionStorage.removeItem('testpaperCardIndex')
//       window.sessionStorage.removeItem('paper')
//       this.$router.push('/testpaper/paperinfo?isKnowledge=1&form=' + JSON.stringify(form))
//     },
//     change (index, bool) {
//       this.list[index].active = bool
//       this.$forceUpdate()
//     }
//   }
// }
</script>

<style lang="scss" scoped>
.table_head {
  height: 58rem;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24rem;
    color: #666660;
    &:nth-child(1) {
      flex: 1;
      text-indent: 70rem;
    }
    &:nth-child(2) {
      flex: 1;
      display: block;
      text-align: center;
    }
    &:nth-child(3) {
      flex: 1;
      text-align: center;
      display: block;
    }
    &:nth-child(4) {
      flex: 1;
      text-align: center;
      display: block;
    }
  }
}
.table_line {
  background: white;
  border-bottom: 1px solid #eaeaea;
  font-size: 22rem;
  line-height: 58rem;
  .two {
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
    .ant-btn {
      font-size: 20rem;
      height: 40rem;
      border-radius: 5rem;
      padding: 0rem 12rem;
    }
    div {
      &:nth-child(1) {
        flex: 1;
        text-indent: 70rem;
        font-weight: bold;
      }
      &:nth-child(2) {
        flex: 1;
        text-indent: 15rem;
        font-weight: bold;
        color: #ff6147;
        font-size: 14px;
        display: block;
        text-align: center;
      }
      &:nth-child(3) {
        flex: 1;
        text-align: center;
      }
      &:nth-child(4) {
        flex: 1;
        text-align: center;
      }
    }
  }
  .three {
    display: flex;
    align-items: center;
    background: #f5faff;
    .ant-btn {
      font-size: 20rem;
      height: 40rem;
      border-radius: 5rem;
      padding: 0rem 12rem;
    }

    div {
      &:nth-child(1) {
        flex: 1;
        text-indent: 70rem;
        font-weight: bold;
      }
      &:nth-child(2) {
        flex: 1;
        text-indent: 15rem;
        font-weight: bold;
        color: #ff6147;
        font-size: 14px;
        display: block;
        text-align: center;
      }
      &:nth-child(3) {
        flex: 1;
        text-align: center;
      }
      &:nth-child(4) {
        flex: 1;
        text-align: center;
      }
    }
  }
}

::v-deep .el-icon-close {
  border: 1px solid #666666;
  border-radius: 50%;
}

.icon {
  text-indent: 0;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #2196f3;
  color: white !important;
}
</style>
