<template>
  <div class="cuotiben_Warp">
    <COMMONTOPSEARCH ref="commonTopSearchRef"
                     @initData="getPaperList"
                     :showSearch="false" />
    <div class="topic_warp">
      <div class="paper"
           v-for="(item,index) in paperList"
           :key="index">
        <div class="content_wrap">
          <div class="top">
            <div class="title">
              {{item.knowledge_desc}}
            </div>
            <div class="pl">
              <span>
                星级:</span>
              <img src="@/static/zhishidian/xx.jpg"
                   style="width:20rem;height:20rem"
                   v-for="(item,idx) in item.knowledge_point_star"
                   :key="idx" />
            </div>
            <div class="pl">
              <span style="flex:1">得分率(%):<span class="num">{{item.rate}}</span></span>
              <span style="flex:1">训练次数:<span class="num">{{item.do_question_count}}</span></span>
            </div>
          </div>
          <div class="btn">
            <button @click="showOtherSecond(item)"
                    v-show="item.child&&item.child.length>0">
                    二级/三级考点
            </button>
          
            <button @click="showOther(item)"
                    v-show="item.videos&&item.videos.length>0&&show_video">
              考点讲解
            </button>
              <button @click="viewLecture(item)"
                    v-show="item.lecture_data&&item.lecture_data.length>0">
              查看讲义
            </button>
            <button @click="toDopaper(item)">
              进入训练
            </button>
          </div>
        </div>

      </div>
    </div>
    <OtherForm ref="otherFormRef" />
    <OtherFormSecond ref="otherFormSecondRef" />
    <OtherFormThird ref="otherFormThirdRef" />
    <LectureForm ref="LectureFormRef" />
  </div>
</template>

<script setup>
import COMMONTOPSEARCH from '@/views/components/COMMON_TOP_SEARCH/index.vue'
import OtherForm from './components/other.vue'
import LectureForm from './components/lecture.vue'
import OtherFormSecond from './components/otherFormSecond.vue'
import OtherFormThird from './components/otherFormThird.vue'
import { ref } from '@vue/reactivity'
import { getWeakList } from '@/api/knowledge.js'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { computed, onMounted } from "@vue/runtime-core";


const router = useRouter()
let paperList = ref([])
let subject_id = ref('')
const otherFormRef = ref(null)
const otherFormSecondRef = ref(null)
const otherFormThirdRef = ref(null)
const LectureFormRef = ref(null)
let show_video = ref(null)
onMounted(() => {
  show_video.value = JSON.parse(window.localStorage.getItem('userInfo')).show_video
 
})
const getPaperList = async (id, keywords) => {
  let params = {
    subject_id: id,
    keywords: keywords,
    show_lecture:true,
  }
  const { data } = await getWeakList(params)
  subject_id.value = id
  paperList.value = data.list.map(item => {
    item.knowledge_point_frequency = item.knowledge_point_frequency.toFixed(2)
    return item
  })
}
const viewLecture = (item) => {
  LectureFormRef.value.list = item.lecture_data
  LectureFormRef.value.dialogVisible = true
}
const showOther = (item) => {
  // if (!item.child || item.child.length == 0) {
  //   message.warning('该条目涉及题目暂时较少，请返回上一级训练')
  //   return
  // }
  // item.child.map(item => {
  //   item.active = false
  //   return item
  // })
  // otherFormRef.value.subjectId = item.subject_id
  otherFormRef.value.list = item.videos
  otherFormRef.value.dialogVisible = true
}
const showOtherThird = (item) => {
  if (!item.child || item.child.length == 0) {
    message.warning('该条目涉及题目暂时较少，请返回上一级训练')
    return
  }
  // item.child.map(item => {
  //   item.active = false
  //   return item
  // })
  const allChildren = item.child.flatMap(childItem => childItem.child || []);
  if (allChildren.length == 0) {
    message.warning('暂无三级考点')
    return
  }

  otherFormThirdRef.value.subjectId = item.subject_id
  otherFormThirdRef.value.list = allChildren
  otherFormThirdRef.value.dialogVisible = true
}
const showOtherSecond = (item) => {
  if (!item.child || item.child.length == 0) {
    message.warning('该条目涉及题目暂时较少，请返回上一级训练')
    return
  }
  item.child.map(item => {
    item.active = false
    return item
  })
  otherFormSecondRef.value.subjectId = item.subject_id
  otherFormSecondRef.value.list = item.child
  otherFormSecondRef.value.dialogVisible = true
}


const toDopaper = (item) => {
  let form = {
    subject_id: subject_id.value,
    knowledge_id: item.knowledge_id
  }
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  router.push('/doPaper?isKnowledgeWeak=1&form=' + JSON.stringify(form))
}

</script>

<style lang="scss" scoped>
.cuotiben_Warp {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background-image: url("../../static/allBackg/弱点训练.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .topic_warp {
    width: calc(100vw - 240rem);
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    margin: 0 auto;
    margin-top: 10rem;
    padding-bottom: 20rem;
    height: calc(100vh - 210rem);
    .paper {
      width: 510rem;
      height: 220rem;
      background: #b29bfa;
      border-radius: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 28rem;
      // margin-bottom: 20rem;
      margin-top: 30rem;
      &:hover {
        .content_wrap {
          height: auto;
          .top {
            height: auto;
            .title {
              display: block;
              height: auto;
              min-height: 40rem;
            }
          }
        }
      }
      .content_wrap {
        width: 490rem;
        height: 200rem;
        box-shadow: 0px 2px 8px 0px rgba(98, 47, 215, 1);
        overflow: hidden;
        border-radius: 20rem;
        .top {
          height: 146rem;
          background: white;
          padding: 16rem;
          .title {
            font-size: 22rem;
            font-weight: bold;
            color: #000000;
            overflow: hidden;
            height: 40rem;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .pl {
            font-size: 22rem;
            line-height: 40rem;
            font-weight: 500;
            color: #999999;
            display: flex;
            align-items: center;
          }
          .num {
            color: #ff6147;
          }
        }
        .btn {
          display: flex;
          height: 54rem;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 2px 8px 0px rgba(98, 47, 215, 1);
          background: #a68cf3;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 auto;
            height: 30rem;
            text-align: center;
            font-size: 22rem;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
            background: transparent;
            border: 0;
            // &:nth-child(1) {
            border-right: 2rem solid rgba(255, 255, 255, 0.5);
            // }
          }
        }
      }
    }
  }
}
// .active {
//   font-weight: bold;
//   color: white !important;
// }
::-webkit-scrollbar-thumb {
  background-color: hsla(217, 100%, 87%, 0.5);
}
</style>
